@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*! Sizes */
    --size-0: 0;
    --size-2: 0.125rem;
    --size-4: 0.25rem;
    --size-6: 0.375rem;
    --size-8: 0.5rem;
    --size-12: 0.75rem;
    --size-16: 1rem;
    --size-20: 1.25rem;
    --size-24: 1.5rem;
    --size-32: 2rem;
    --size-40: 2.5rem;
    --size-48: 3rem;
    --size-56: 3.5rem;
    --size-64: 4rem;
    --size-72: 4.5rem;
    --size-80: 5rem;
    --size-100: 6.25rem;
    --size-120: 7.5rem;
    --size-160: 10rem;
    --size-200: 12.5rem;
    --size-375: 23.4375rem;
    --size-420: 26.25rem;
    --size-500: 31.25rem;
    --size-800: 50rem;
    --size-1000: 62.5rem;
    --size-1200: 75rem;

    /*! Typography */
    /* size: 64px / l-height: 150% / weight: 900 */
    .display-large {
      font-size: 4rem;
      line-height: 1.5;
      font-weight: 900;

      /* size: 48px / l-height: 150% / weight: 900 */
      @media (max-width: 640px) {
        font-size: 3rem;
      }
    }

    /* size: 48px / l-height: 150% / weight: 900 */
    .display-medium {
      font-size: 3rem;
      line-height: 1.5;
      font-weight: 900;
    }

    /* size: 34px / l-height: 150% / weight: 800 */
    .display-small {
      font-size: 2.125rem;
      line-height: 1.3;
      font-weight: 800;

      /* size: 28px / l-height: 150% / weight: 800 */
      @media (max-width: 640px) {
        font-size: 1.75rem;
      }
    }

    /* size: 28px / l-height: 150% / weight: 700 */
    .h1 {
      font-size: 1.75rem;
      line-height: 1.5;
      font-weight: 700;

      /* size: 24px / l-height: 150% / weight: 700 */
      @media (max-width: 640px) {
        font-size: 1.5rem;
      }
    }

    /* size: 24px / l-height: 150% / weight: 700 */
    .h2 {
      font-size: 1.5rem;
      line-height: 1.5;
      font-weight: 700;

      /* size: 22px / l-height: 150% / weight: 700 */
      @media (max-width: 640px) {
        font-size: 1.375rem;
      }
    }

    /* size: 22px / l-height: 150% / weight: 700 */
    .h3 {
      font-size: 1.375rem;
      line-height: 1.5;
      font-weight: 400;

      /* size: 20px / l-height: 150% / weight: 700 */
      @media (max-width: 640px) {
        font-size: 1.25rem;
      }
    }

    /* size: 20px / l-height: 150% / weight: 400 */
    .subtitle1-regular {
      font-size: 1.25rem;
      line-height: 1.5;
      font-weight: 400;

      /* size: 18px / l-height: 150% / weight: 400 */
      @media (max-width: 640px) {
        font-size: 1.125rem;
      }
    }
    /* size: 20px / l-height: 150% / weight: 600 */
    .subtitle1-semibold {
      font-size: 1.25rem;
      line-height: 1.5;
      font-weight: 600;

      /* size: 18px / l-height: 150% / weight: 600 */
      @media (max-width: 640px) {
        font-size: 1.125rem;
      }
    }
    /* size: 20px / l-height: 150% / weight: 700 */
    .subtitle1-bold {
      font-size: 1.25rem;
      line-height: 1.5;
      font-weight: 700;

      /* size: 18px / l-height: 150% / weight: 700 */
      @media (max-width: 640px) {
        font-size: 1.125rem;
      }
    }
    /* size: 18px / l-height: 150% / weight: 400 */
    .subtitle2-regular {
      font-size: 1.125rem;
      line-height: 1.5;
      font-weight: 400;

      /* size: 16px / l-height: 150% / weight: 400 */
      @media (max-width: 640px) {
        font-size: 1rem;
      }
    }
    /* size: 18px / l-height: 150% / weight: 600 */
    .subtitle2-semibold {
      font-size: 1.125rem;
      line-height: 1.5;
      font-weight: 600;

      /* size: 16px / l-height: 150% / weight: 600 */
      @media (max-width: 640px) {
        font-size: 1rem;
      }
    }
    /* size: 18px / l-height: 150% / weight: 700 */
    .subtitle2-bold {
      font-size: 1.125rem;
      line-height: 1.5;
      font-weight: 700;

      /* size: 16px / l-height: 150% / weight: 700 */
      @media (max-width: 640px) {
        font-size: 1rem;
      }
    }

    /* size: 16px / l-height: 150% / weight: 400 */
    .body1-regular {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 400;
    }
    /* size: 16px / l-height: 150% / weight: 600 */
    .body1-semibold {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 600;
    }
    /* size: 16px / l-height: 150% / weight: 700 */
    .body1-bold {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 700;
    }
    /* size: 16px / l-height: 150% / weight: 700 */
    .body1-extrabold {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 800;
    }
    /* size: 14px / l-height: 150% / weight: 400 */
    .body2-regular {
      font-size: 0.875rem;
      line-height: 1.5;
      font-weight: 400;
    }
    /* size: 14px / l-height: 150% / weight: 600 */
    .body2-semibold {
      font-size: 0.875rem;
      line-height: 1.5;
      font-weight: 600;
    }
    /* size: 14px / l-height: 150% / weight: 700 */
    .body2-bold {
      font-size: 0.875rem;
      line-height: 1.5;
      font-weight: 700;
    }
    /* size: 14px / l-height: 150% / weight: 700 */
    .body2-extrabold {
      font-size: 0.875rem;
      line-height: 1.5;
      font-weight: 800;
    }
    /* size: 16px / l-height: 150% / weight: 700 */
    .cta-large {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 700;
    }
    /* size: 14px / l-height: 150% / weight: 700 */
    .cta-medium {
      font-size: 0.875rem;
      line-height: 1.5;
      font-weight: 700;
    }
    /* size: 13px / l-height: 150% / weight: 400 */
    .caption1-regular {
      font-size: 0.8125rem;
      line-height: 1.5;
      font-weight: 400;
    }
    /* size: 12px / l-height: 150% / weight: 400 */
    .caption2-regular {
      font-size: 0.75rem;
      line-height: 1.5;
      font-weight: 400;
    }
    /* size: 12px / l-height: 150% / weight: 400 */
    .caption2-regular {
      font-size: 0.75rem;
      line-height: 1.5;
      font-weight: 400;
    }
    /* size: 12px / l-height: 150% / weight: 600 */
    .caption2-semibold {
      font-size: 0.75rem;
      line-height: 1.5;
      font-weight: 600;
    }
    /* size: 12px / l-height: 150% / weight: 700 */
    .caption2-bold {
      font-size: 0.75rem;
      line-height: 1.5;
      font-weight: 700;
    }
    /* size: 12px / l-height: 150% / weight: 800 */
    .caption2-extrabold {
      font-size: 0.75rem;
      line-height: 1.5;
      font-weight: 800;
    }
    /* size: 12px / l-height: 150% / weight: 400 */
    .caption3-regular {
      font-size: 0.625rem;
      line-height: 1.5;
      font-weight: 400;
    }
    /* size: 12px / l-height: 150% / weight: 800 */
    .caption3-extrabold {
      font-size: 0.625rem;
      line-height: 1.5;
      font-weight: 800;
    }

    --navy-25: 210 15% 95%;
    --navy-50: 222 24% 92%;
    --navy-100: 218 17% 74%;
    --navy-200: 219 16% 62%;
    --navy-300: 220 20% 44%;
    --navy-400: 220 33% 33%;
    --navy-500: 220 81% 17%;
    --navy-600: 220 80% 12%;
    --navy-700: 219 69% 9%;
    --navy-800: 219 83% 7%;
    --navy-900: 223 78% 2%;

    --yellow-25: 47 100% 98%;
    --yellow-50: 48 100% 96%;
    --yellow-100: 50 100% 88%;
    --yellow-200: 49 100% 82%;
    --yellow-300: 49 100% 73%;
    --yellow-400: 49 100% 69%;
    --yellow-500: 50 100% 60%;
    --yellow-600: 44 100% 62%;
    --yellow-700: 43 95% 60%;
    --yellow-800: 43 79% 56%;
    --yellow-900: 43 64% 51%;

    --dark-blue-25: 225 72% 96%;
    --dark-blue-50: 225 50% 83%;
    --dark-blue-100: 219 46% 72%;
    --dark-blue-200: 220 35% 62%;
    --dark-blue-300: 219 29% 54%;
    --dark-blue-400: 219 32% 41%;
    --dark-blue-500: 219 35% 32%;
    --dark-blue-600: 219 35% 29%;
    --dark-blue-700: 219 34% 23%;
    --dark-blue-800: 218 35% 18%;
    --dark-blue-900: 217 35% 14%;

    --light-blue-25: 191 100% 98%;
    --light-blue-50: 190 100% 97%;
    --light-blue-100: 191 95% 93%;
    --light-blue-200: 192 97% 90%;
    --light-blue-300: 192 98% 85%;
    --light-blue-400: 191 96% 82%;
    --light-blue-500: 192 97% 78%;
    --light-blue-600: 192 86% 61%;
    --light-blue-700: 191 100% 44%;
    --light-blue-800: 191 99% 39%;
    --light-blue-900: 192 91% 35%;

    --neutral-25: 0 0% 100%;
    --neutral-50: 214 100% 99%;
    --neutral-100: 225 50% 97%;
    --neutral-200: 227 32% 95%;
    --neutral-300: 227 28% 88%;
    --neutral-400: 230 11% 80%;
    --neutral-500: 222 8% 68%;
    --neutral-600: 220 6% 54%;
    --neutral-700: 216 6% 38%;
    --neutral-800: 216 5% 23%;
    --neutral-900: 0 0% 0%;

    --blue-25: 202 100% 96%;
    --blue-50: 203 75% 89%;
    --blue-100: 211 62% 82%;
    --blue-200: 212 62% 73%;
    --blue-300: 212 62% 61%;
    --blue-400: 212 62% 53%;
    --blue-500: 212 100% 44%;
    --blue-600: 216 84% 41%;
    --blue-700: 228 64% 40%;
    --blue-800: 228 70% 31%;
    --blue-900: 212 100% 21%;

    --green-25: 148 69% 97%;
    --green-50: 149 58% 94%;
    --green-100: 147 59% 85%;
    --green-200: 148 59% 74%;
    --green-300: 148 58% 63%;
    --green-400: 148 59% 55%;
    --green-500: 142 46% 51%;
    --green-600: 145 73% 43%;
    --green-700: 144 77% 40%;
    --green-800: 141 84% 36%;
    --green-900: 137 55% 36%;

    --red-25: 349 100% 96%;
    --red-50: 352 100% 94%;
    --red-100: 351 100% 83%;
    --red-200: 351 100% 77%;
    --red-300: 351 100% 72%;
    --red-400: 352 100% 62%;
    --red-500: 353 82% 45%;
    --red-600: 353 80% 40%;
    --red-700: 353 83% 36%;
    --red-800: 353 82% 30%;
    --red-900: 352 80% 23%;

    --pink-25: 335 100% 99%;
    --pink-50: 336 100% 97%;
    --pink-100: 336 97% 90%;
    --pink-200: 336 98% 85%;
    --pink-300: 336 97% 78%;
    --pink-400: 336 98% 74%;
    --pink-500: 336 97% 67%;
    --pink-600: 336 75% 61%;
    --pink-700: 336 49% 48%;
    --pink-800: 336 49% 37%;
    --pink-900: 336 49% 29%;
    
    --purple-25: 256 100% 100%;
    --purple-50: 254 100% 98%;
    --purple-100: 254 100% 93%;
    --purple-200: 255 100% 89%;
    --purple-300: 255 100% 84%;
    --purple-400: 255 100% 80%;
    --purple-500: 255 100% 76%;
    --purple-600: 255 72% 69%;
    --purple-700: 255 38% 54%;
    --purple-800: 255 34% 42%;
    --purple-900: 255 33% 32%;

    --fg-strong: var(--navy-700);
    --fg-default: var(--navy-500);
    --fg-weak: var(--dark-blue-400);
    --fg-weaker: var(--dark-blue-200);
    --fg-weakest: var(--neutral-500);
    --fg-inverse-default: var(--neutral-25);
    
    --border-strong: var(--navy-50);
    --border-stronger: var(--navy-100);
    --border-strongest: var(--navy-400);
    --border-default: var(--dark-blue-25);
    --border-inverse-default: var(--neutral-25);

    --bg-body: var(--neutral-25);
    --bg-overlay: hsl(var(--navy-700) / 0.5);
    --bg-strong: var(--neutral-100);
    --bg-stronger: var(--neutral-200);
    --bg-default: 213 100% 98%;
    --bg-strongest: var(--navy-500);
    --bg-weak: var(--blue-25);

    /* --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 220 81% 17%;
    --primary-foreground: 210 40% 98%;
    --secondary: 49 100% 60%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%; */
  }

  * {
    @apply border-border-default;
  }
  
  body {
    @apply bg-bg-body text-fg-default;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

@layer components {
  .animate-link-center {
    @apply relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-fg-inverse-default after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center after:bottom-0 after:z-20;
  }
  .animate-link-left {
    @apply relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left after:bottom-0 after:z-20;
  }
  .animate-link-right {
    @apply relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-right after:bottom-0 after:z-20;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }

  @keyframes spinner-animation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  @keyframes spinner-progress {
    0% { stroke-dashoffset: 364; transform: rotate(0deg); }
    100% { stroke-dashoffset: 0; transform: rotate(360deg); }
  }

  /*! crcb = custom-role-circle-background */
  @keyframes crcb {
    0% {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
    100% {
      transform: scale(1.2);
    }
  }

  /*! crc = custom-role-circle */
  @keyframes crc {
    0% {
      transform: rotate(0deg) translateX(1rem) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translateX(1rem) rotate(-360deg);
    }
  }

  /* for Select - start */
  html {
    scrollbar-gutter: stable;
  }

  html body[data-scroll-locked] {
    --removed-body-scroll-bar-size: 0 !important;
    margin-right: 0 !important;
  }
  /* for Select - end */
}

.grecaptcha-badge {
  z-index: 100 !important;
  bottom: 94px !important;
}
